import { TWidget } from 'widgets/Widget';

/**
 * Creates the CtaExt class, which extends the provided Widget base class.
 * CtaExt is a component that handles custom CTA (Call to Action) functionality, 
 * including event emission and dialog interactions.
 * 
 * @param {TWidget} Widget - The base widget class for extension.
 * @returns {CtaExt} - The extended CtaExt class.
 */
function CtaExtClassCreator(Widget: TWidget) {
    /**
     * @class CtaExt
     * @augments Widget
     * @classdesc Represents a CTA (Call to Action) component with specific features:
     * 1. Emits a GTM event when clicked.
     * 2. Transfers inline styles from the CTA to its wrapper.
     * 3. Opens and handles dialogs with specific interactions.
     */
    class CtaExt extends Widget {
        /**
         * @private
         * @type {boolean}
         * @description Flag to check if the close listener is already attached to prevent multiple attachments.
         */
        private isCloseListenerAttached = false;

        /**
         * Initializes the widget and transfers attributes to the wrapper.
         * Called when the component is first rendered.
         */
        init() {
            super.init();
            this.transferAttributes();
        }

        /**
         * Provides the preferences for the CtaExt component, extending the base preferences.
         * 
         * @returns {object} - An object containing the preferences, including a dialog ID.
         */
        prefs() {
            return {
                dialogId: '',
                ...super.prefs()
            };
        }

        /**
         * Called when the widget is re-rendered, typically after a refresh.
         * Transfers styles to the wrapper element again.
         */
        onRefresh() {
            super.onRefresh();
            this.transferAttributes();
        }

        /**
         * Handles the click event on the CTA element.
         * Emits a custom event, opens the associated dialog if a dialog ID is set, and attaches the close listener if not already attached.
         * 
         * @param {HTMLElement} elem - The target element that was clicked.
         */
        onCtaExtClick(elem: HTMLElement) {
            this.eventBus().emit('ctaExt.click', elem);

            const dialogId = this.prefs().dialogId;

            if (dialogId) {
                const dialogContainer = window.document.querySelector(`[data-id="${dialogId}"]`);

                if (dialogContainer) {
                    dialogContainer.classList.add('m-opened');
                    dialogContainer.removeAttribute('hidden');

                    const dialog = dialogContainer.querySelector('[data-ref="dialog"]');

                    dialog?.classList.add('m-active', 'm-top_dialog');
                    this.eventBus().emit('show.dialog');

                    if (!this.isCloseListenerAttached) {
                        this.attachCloseListener();
                        this.isCloseListenerAttached = true; // Set flag to true after attaching listener
                    }
                }
            }
        }

        /**
         * Transfers inline styles from the inner CTA element to its outer wrapper element.
         * Ensures consistent styling when the CTA is nested within other components.
         */
        transferAttributes() {
            const ctaWrapper = this.ref('self').get()?.closest('[data-ref="ctaExtWrapper"]');

            if (ctaWrapper) {
                ctaWrapper.setAttribute('style', this.ref('self').attr('style'));
            }
        }

        /**
         * Attaches a click event listener to the document to handle closing dialogs.
         * The listener closes the dialog when an element with `data-dialog-close="true"` is clicked.
         * Prevents adding multiple listeners by using `isCloseListenerAttached`.
         */
        attachCloseListener() {
            document.addEventListener('click', function(event) {
                const target = event.target as HTMLElement | null;

                if (target) {
                    const closeButton = target.closest('[data-dialog-close="true"]');

                    if (closeButton) {
                        const dialogContainer = closeButton.closest('[data-dialog-content="true"]');

                        if (dialogContainer) {
                            dialogContainer.setAttribute('hidden', 'hidden');
                        }
                    }
                }
            });
        }
    }

    return CtaExt;
}

export type TCtaExt = ReturnType<typeof CtaExtClassCreator>;
export type TCtaExtInstance = InstanceType<TCtaExt>;

export default CtaExtClassCreator;
