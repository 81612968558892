/**
 * Function to create the CarouselExt class by extending the base Carousel class.
 * @param Carousel - The base Carousel class.
 * @returns {typeof CarouselExt} - The extended Carousel class.
 */
function CarouselExtClassCreator(Carousel: ReturnType<typeof import('widgets/global/Carousel').default>) {
    /**
     * * @category widgets
     * @subcategory plugin_page_designer_ext
     * @class CarouselExt
     * @augments Widget
     * @classdesc Represents Carousel component with next features:
     * 1. Allow to set carousel direction based on the view type
     * 2. Allow to use pagination for carousel rendered by mustache template
     * 3. Allow to scroll carousel to the next/previous/custom( index can be passed to the method) page index
     * 4. Allow to scroll by on the page click(can be used for carousel with thumbnails)
     * 5. Allow to scroll to custom element's position
     * 6. Allow to scroll to focused element
     * 7. Support mousemove, touchmove, mouseup, mousedown, keydown event so we can use carousel even on touch devices.
     * Also we can control carouse with keyboard
     * 8. Allow to get carousel images
     *
     * <br>Uses as a basis slider from here (ScrollCarousel.js):
     * <br>https://github.com/dimanech/aria-components/tree/master/cartridge1/js/components/carousels/carousel
     * @property {string} data-widget - Widget name `carousel`
     * @property {string} data-elem-prev-button - Previous button element
     * @property {string} data-elem-next-button - Next button element
     * @property {string} data-elem-carousel-track - Carousel inner element
     * @property {string} data-direction - Carousel direction - an object, contains direction per viewport
     * @example <caption>Example of Carousel widget usage</caption>
     * <div
     *     data-widget="carouselExt"
     *     data-elem-prev-button="elemPrevButton"
     *     data-elem-next-button="elemNextButton"
     *     data-elem-carousel-track="elemCarouselTrack"
     *     data-direction='{
     *         "small": "horizontal",
     *         "medium": "horizontal",
     *         "large": "vertical",
     *         "extraLarge": "vertical"
     *     }'
     * >
     *     <button
     *         class="carousel__ctrl _prev"
     *         data-elem-prev-button
     *         tabindex="-1"
     *         aria-hidden="true"
     *         data-ref="elemPrevButton"
     *         data-event-click="scrollToPrevPage"
     *     >Prev</button>
     *     <div
     *         class="carousel__track"
     *         data-elem-carousel-track
     *         data-ref="elemCarouselTrack"
     *         data-event-scroll="onScroll"
     *         data-event-touchstart="onScroll"
     *         data-event-mousedown.prevent="onMouseDown"
     *         data-event-mouseup="onMouseUp"
     *     >
     *         <isloop items="${slotcontent.content}" var="contentAsset">
     *             <div class="box _single" tabindex="0">
     *                 <isprint value="${contentAsset.custom.body}" encoding="off" />
     *             </div>
     *         </isloop>
     *     </div>
     *     <button
     *         class="carousel__ctrl _next"
     *         data-elem-next-button
     *         tabindex="-1"
     *         aria-hidden="true"
     *         data-ref="elemNextButton"
     *         data-event-click="scrollToNextPage"
     *     >Next</button>
     *     <div class="pagination" data-ref="pagination"></div>
     *     <script type="template/mustache" data-ref="template">
     *         <div class="pagination" data-ref="pagination">
     *             {{${'#'}pagination}}
     *             <button
     *                 class="page"
     *                 data-page="{{page}}"
     *                 tabindex="-1"
     *                 data-event-click.prevent="handlePaginationClick"
     *             >
     *             </button>
     *             {{/pagination}}
     *         </div>
     *     </script>
     * </div>
     */

    /**
     * Extends the base Carousel class to provide additional functionality.
     */
    class CarouselExt extends Carousel {
        elemCarouselTrack!: HTMLElement;

        currentPageIndex = 0;

        slidesQty = 0;

        slideStart: Array<number> = [];

        slides!: HTMLCollectionOf<HTMLElement>;

        visibleItems = 0;

        contentWidth = 0;

        howToScroll = 'one';

        /**
         * Initializes the carousel, sets dimensions, and updates the layout.
         */
        initCarousel() {
            this.setCarouselDimensions();
            this.scrollToPage(0);
            this.getVisibleItems();
            this.getContentWidth();
            super.initCarousel();
            this.updateParentGridLayout();
        }

        /**
         * Handles changes in view type by updating the carousel.
         */
        onViewtypeChange() {
            super.onViewtypeChange();
            super.update();
        }

        /**
         * Sets the dimensions of the carousel and calculates the positions of the slides.
         */
        setCarouselDimensions() {
            this.elemCarouselTrack = super.ref('elemCarouselTrack').get() as HTMLElement;
            this.currentPageIndex = 0;
            this.slidesQty = this.elemCarouselTrack.childElementCount;
            this.slideStart = [];
            this.slides = this.elemCarouselTrack.children as HTMLCollectionOf<HTMLElement>;

            if (this.elemCarouselTrack) {
                for (let i = 0; i < this.slides.length; i++) {
                    const firstChild = Array.from(this.slides[i].children).find(child => child.nodeName !== 'STYLE') as HTMLElement;
                    const width = firstChild ? firstChild?.dataset.slideType : '';
                    const widthArr = width ? width.split(' ') : '';

                    this.slideStart[i] = 0;

                    for (let x = 0; x < widthArr.length; x++) {
                        const name = widthArr[x].replace(/-/g, '_').replace(/(^| )/g, 'm-');

                        this.slides[i].classList.add(name);
                    }

                    for (let j = 0; j < i; j++) {
                        this.slideStart[i] += this.slides[j].clientWidth;
                    }
                }
            }
        }

        getVisibleItems() {
            const carouselTrackElem = this.ref(this.prefs().elemCarouselTrack).get();
            const closestParent = carouselTrackElem?.closest('.l-grid_layout-content');
            const browserWidth = closestParent ? closestParent.clientWidth : window.innerWidth;
            // Get the first child element
            const firstChild = closestParent ? closestParent.firstElementChild : null;
            const count = closestParent ? closestParent.children.length : 0;
            let additionalWidth = 0;

            if (count > 1) {
                additionalWidth = firstChild ? firstChild.clientWidth : 0;
            }

            if (carouselTrackElem) {
                this.visibleItems = Math.round((browserWidth - additionalWidth) / carouselTrackElem.children[0].clientWidth || 1) || 1;
                this.howToScroll = carouselTrackElem?.dataset.howtoscroll || 'one';
            }
        }

        getContentWidth() {
            const carouselTrackElem = this.ref(this.prefs().elemCarouselTrack).get();
            const closestParent = carouselTrackElem ? carouselTrackElem.closest('.l-grid_layout-content') : null;
            // Ensure first and second children are HTMLElements for accessing offsetTop
            const firstParentChild = closestParent ? closestParent.firstElementChild as HTMLElement : null;
            const secondParentChild = closestParent && closestParent.children.length > 1 ? closestParent.children[1] as HTMLElement : null;
            const count = closestParent ? closestParent.children.length : 0;

            this.contentWidth = 0;

            if (count > 1) {
                // Check if the first and second children are in the same row by comparing their offsetTop
                if (firstParentChild && secondParentChild && firstParentChild.offsetTop === secondParentChild.offsetTop) {
                    this.contentWidth = firstParentChild.clientWidth; // Set the content width
                }
            }
        }

        /**
         * Refreshes the carousel by resetting dimensions and updating the layout.
         */
        onRefresh() {
            this.setCarouselDimensions();
            super.onRefresh();
            this.updateParentGridLayout();
        }

        /**
         * Scrolls the carousel to a specific page index.
         * @param {number} index - The index of the page to scroll to.
         * @returns {CarouselExt} - The instance of the carousel.
         */
        scrollToPage(index: number) {
            this.elemCarouselTrack.scrollTo({ top: 0, left: this.slideStart[index], behavior: 'smooth' });

            return this;
        }

        /**
         * Scrolls the carousel one page forward.
         */
        scrollOneForward() {
            this.currentPageIndex += this.currentPageIndex < this.slidesQty ? 1 : 0;
            this.scrollToPage(this.currentPageIndex);
            this.isNavButtonClicked = true;
        }

        /**
         * Scrolls the carousel one page backward.
         */
        scrollOneBack() {
            this.currentPageIndex -= (this.currentPageIndex > 0 ? 1 : 0);
            this.scrollToPage(this.currentPageIndex);
            this.isNavButtonClicked = true;
        }

        /**
         * Updates the metrics of the carousel such as scroll start and end positions.
         */
        updateCarouselMetric() {
            const carouselElem = this.ref('elemCarouselBody').get();
            const roundingDelta = this.roundingDelta || 0;

            if (this.elemCarouselTrack && carouselElem) {
                const totalScrollWidth = this.elemCarouselTrack.scrollLeft + carouselElem.offsetWidth;

                this.isScrollStart = this.elemCarouselTrack.scrollLeft <= 0;
                this.isScrollEnd = (totalScrollWidth + roundingDelta) >= this.elemCarouselTrack.scrollWidth;
            }
        }

        /**
         * Handles the end of a scroll event and triggers the appropriate data layer event.
         */
        onScrollEnd() {
            super.onScrollEnd();
            const totalScrollWidth = Math.trunc(this.elemCarouselTrack.scrollLeft + this.elemCarouselTrack.offsetLeft);
            const allSlidesAttr = Array.from(this.slides).map((item, index) => {
                return {
                    leftPosition: item.offsetLeft,
                    'slide-index': index
                };
            });

            for (let i = 0; i < allSlidesAttr.length; i++) {
                if (totalScrollWidth <= allSlidesAttr[i].leftPosition) {
                    this.currentPageIndex = allSlidesAttr[i]['slide-index'];
                    emitSlideViewEvent(this.slides[this.currentPageIndex], this.elemCarouselTrack, this.currentPageIndex);  // Emit the event for the active slide(s)
                    break;
                }
            }
        }

        /**
         * Updates the layout of the parent grid if the carousel is part of a specific class.
         */
        updateParentGridLayout() {
            const gridLayoutElement = this.elemCarouselTrack.closest('.l-grid_layout') as HTMLElement | null;

            if (gridLayoutElement && this.elemCarouselTrack.closest('.b-carousel_ext.mmr')) {
                gridLayoutElement.style.maxWidth = '100%';
                gridLayoutElement.style.paddingTop = '0px';  // Example of additional style change
            }
        }
    }

    return CarouselExt;
}

/**
 * Extracts the `icid` parameter from an anchor element's href attribute.
 * @param {HTMLAnchorElement} anchor - The anchor element to extract the `icid` from.
 * @returns {string | null} - The extracted `icid` or null if not found.
 */
function getICIDFromAnchor(anchor: HTMLAnchorElement): string | null {
    const url = new URL(anchor.href);

    return url.searchParams.get('icid');
}

/**
 * Emits a slide view event to the data layer for the active slide(s).
 * @param {HTMLElement} slide - The slide element to emit the event for.
 * @param {HTMLElement} elemCarouselTrack - The carousel track element.
 * @param {number} currentPageIndex - The current page index of the carousel.
 */
function emitSlideViewEvent(slide: HTMLElement, elemCarouselTrack: HTMLElement, currentPageIndex: number) {
    if (!elemCarouselTrack.closest('.b-carousel_ext.mmr')) {
        return;
    }

    const anchors = slide.querySelectorAll('a');
    const icids = Array.from(anchors).map(anchor => getICIDFromAnchor(anchor)).filter(icid => icid);

    if (isMobileViewport()) {
        // Mobile viewport: emit event for the single visible slide
        if (icids.length > 0) {
            const el = icids[0] as string;

            if (elemCarouselTrack.dataset.lastMsgView !== el) {
                elemCarouselTrack.setAttribute('data-last-msg-view', el);
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'multiMsgView',
                    values: icids
                });
            }
        }
    } else {
        // Non-mobile viewport: emit event for all visible slides
        const visibleSlides = Array.from(slide.parentElement?.children || []).slice(currentPageIndex, currentPageIndex + 3);
        const allVisibleIcids = visibleSlides.flatMap(visibleSlide => {
            const visibleAnchors = visibleSlide.querySelectorAll('a');

            return Array.from(visibleAnchors).map(anchor => getICIDFromAnchor(anchor)).filter(icid => icid);
        });

        if (allVisibleIcids.length > 0) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'multiMsgView',
                values: allVisibleIcids
            });
        }
    }
}

/**
 * Checks if the current viewport is mobile based on the window width.
 * @returns {boolean} - True if the viewport is mobile, false otherwise.
 */
function isMobileViewport(): boolean {
    return window.innerWidth <= 768; // Adjust the breakpoint as needed
}

export type TCarouselExt = ReturnType<typeof CarouselExtClassCreator>;

export type TCarouselExtInstance = InstanceType<TCarouselExt>;

export default CarouselExtClassCreator;
