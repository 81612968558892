import WidgetsMgr from 'widgets/widgetsMgr';
import AccessibilityFocusTrapMixin from 'widgets/global/AccessibilityFocusTrapMixin';
import Modal from 'widgets/global/Modal';
import SwipeToClose from 'widgets/global/SwipeToClose';
import AccordionItem from 'widgets/global/AccordionItem';
import InputTel from 'widgets/forms/InputTel';
import EmailSubscribeExt from 'hallmark/forms/ajax/EmailSubscribeExt';

// Synchronous widget list
WidgetsMgr.addWidgetsList('app_custom_hmk', () => [
    ['emailSubscribe', EmailSubscribeExt, 'emailSubscribe']
]);

const hasContext = (context) => window.contexts.includes(context);

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('global'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'header.widgets' */'hallmark/global/DisclosureMixin').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/forms/InputSelectSort').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/global/GlobalAlertsMixin').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/global/ProcessButtonPersonalizationMixin').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/global/VideoPlayer').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/search/SearchBox').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/global/AccordionItemMixin').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/store/StorePickup').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/store/StorePickupInvoker').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/store/StorePickupForm').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/global/AudioPlayer').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/global/CarouselSimple').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/forms/InputPasswordMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'hallmark/product/ProductTile').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/product/QuickBuyMixin').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/forms/InputSelectMixin').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/forms/ajax/LogOutForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/PasswordResetForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/forms/InputTelMixin').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/global/ChatBot').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/global/ChatBotBtn').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/global/ChatBotIframe').then(d => d.default),
        import(/* webpackChunkName: 'product.carousel' */ 'hallmark/global/CarouselMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.carousel' */ 'hallmark/forms/AjaxFormMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.carousel' */ 'hallmark/forms/BasicInputMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.carousel' */ 'hallmark/global/Tooltip').then(d => d.default),
        import(/* webpackChunkName: 'product.carousel' */ 'hallmark/global/LoginDropDown').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/CrownRewardsModal').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/LinkAccountsModal').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/HPlusUpdateProfileModal').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/HPlusModals').then(d => d.default)
    ]).then(deps => {
        const [
            DisclosureMixin,
            InputSelectSort,
            GlobalAlertsMixin,
            ProcessButtonPersonalizationMixin,
            VideoPlayer,
            SearchBox,
            AccordionItemMixin,
            StorePickup,
            StorePickupInvoker,
            StorePickupForm,
            AudioPlayer,
            CarouselSimple,
            InputPasswordMixin,
            ProductTile,
            QuickBuyMixin,
            InputSelectMixin,
            LogOutForm,
            PasswordResetForm,
            InputTelMixin,
            ChatBot,
            ChatBotBtn,
            ChatBotIframe,
            CarouselMixin,
            AjaxFormMixin,
            BasicInputMixin,
            Tooltip,
            LoginDropDown,
            CrownRewardsModal,
            LinkAccountsModal,
            HPlusUpdateProfileModal,
            HPlusModals
        ] = deps;

        return {
            listId: 'hmk.global.widgets',
            widgetsDefinition: () => [
                ['disclosure', AccessibilityFocusTrapMixin, 'disclosure'],
                ['disclosure', DisclosureMixin, 'disclosure'],
                ['inputSelectSort', InputSelectSort, 'inputSelect'],
                ['globalAlerts', GlobalAlertsMixin, 'globalAlerts'],
                ['processButton', ProcessButtonPersonalizationMixin, 'processButton'],
                ['videoPlayer', VideoPlayer],
                ['searchBox', SearchBox, 'searchBox'],
                ['accordionItem', AccordionItemMixin, 'accordionItem'],
                ['storePickup', StorePickup, 'modal'],
                ['storePickupInvoker', StorePickupInvoker],
                ['storePickupForm', StorePickupForm, 'ajaxform'],
                ['audioPlayer', AudioPlayer],
                ['carouselSimple', CarouselSimple, 'carousel'],
                ['inputPassword', InputPasswordMixin, 'inputPassword'],
                ['productTile', ProductTile, 'productTile'],
                ['productTile', QuickBuyMixin, 'productTile'],
                ['inputSelect', InputSelectMixin, 'inputSelect'],
                ['logOutForm', LogOutForm, 'ajaxform'],
                ['passwordResetForm', PasswordResetForm, 'ajaxform'],
                ['inputTel', InputTelMixin, 'inputTel'],
                ['chatBot', ChatBot],
                ['chatBotBtn', ChatBotBtn],
                ['chatBotIframe', ChatBotIframe],
                ['ajaxform', AjaxFormMixin, 'ajaxform'],
                ['basicInput', BasicInputMixin, 'basicInput'],
                ['carousel', CarouselMixin, 'carousel'],
                ['tooltip', Tooltip],
                ['loginDropDown', LoginDropDown],
                ['crownRewardsModal', CrownRewardsModal, 'modal'],
                ['linkAccountsModal', LinkAccountsModal, 'modal'],
                ['hPlusUpdateProfileModal', HPlusUpdateProfileModal, 'modal'],
                ['hplusModals', HPlusModals]
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('header'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'header.widgets' */'hallmark/header/MenuBarItemMixin').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/cart/MinicartMixin').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/cart/MinicartDialogMixin').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'hallmark/header/MegaMenuMixin').then(d => d.default)
    ]).then(deps => {
        const [
            MenuBarItemMixin,
            MinicartMixin,
            MinicartDialogMixin,
            MegaMenuMixin
        ] = deps;

        return {
            listId: 'hmk.header.widgets',
            widgetsDefinition: () => [
                ['menuBarItem', MenuBarItemMixin, 'menuBarItem'],
                ['minicart', MinicartMixin, 'minicart'],
                ['minicartDialog', MinicartDialogMixin, 'minicartDialog'],
                ['megaMenu', MegaMenuMixin, 'megaMenu']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('plp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'search.widgets' */ 'hallmark/search/RefinementsAccordion').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'hallmark/search/RefinementsAccordionItem').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'hallmark/search/DeliveryRefinement').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */'hallmark/search/ProductListingMgrMixin').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/global/SignInModalBtn').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/ajax/LoginFormMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/CrownRewardsModal').then(d => d.default)
    ]).then(deps => {
        const [
            RefinementsAccordion,
            RefinementsAccordionItem,
            DeliveryRefinement,
            ProductListingMgrMixin,
            SignInModalBtn,
            LoginForm,
            LoginFormMixin,
            CrownRewardsModal
        ] = deps;

        return {
            listId: 'hmk.search.widgets',
            widgetsDefinition: () => [
                ['refinementsAccordion', Modal, 'accordion'],
                ['refinementsAccordion', AccessibilityFocusTrapMixin, 'refinementsAccordion'],
                ['refinementsAccordion', SwipeToClose, 'refinementsAccordion'],
                ['refinementsAccordion', RefinementsAccordion, 'refinementsAccordion'],
                ['refinementsAccordionItem', AccordionItem, 'listAccessibility'],
                ['refinementsAccordionItem', RefinementsAccordionItem, 'refinementsAccordionItem'],
                ['deliveryRefinement', DeliveryRefinement, 'refinement'],
                ['productListingMgr', ProductListingMgrMixin, 'productListingMgr'],
                ['signInModalBtn', SignInModalBtn],
                ['loginForm', LoginForm, 'ajaxform'],
                ['loginFormMixin', LoginFormMixin, 'loginForm'],
                ['crownRewardsModal', CrownRewardsModal, 'modal']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('storelocator'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'storelocator.widgets' */ 'hallmark/storelocator/StoreSearchForm').then(d => d.default)
    ]).then(deps => {
        const [
            StoreSearchForm
        ] = deps;

        return {
            listId: 'hmk.storelocator.widgets',
            widgetsDefinition: () => [
                ['storeSearchForm', StoreSearchForm, 'storeSearchForm']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.widgets' */ 'hallmark/product/ProductDetailMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'hallmark/global/VideoModal').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'hallmark/product/DeliveryOptionSelect').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'hallmark/product/AddAllToCartMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'hallmark/product/ProductAvailabilityMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'hallmark/product/AddToCartMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'hallmark/product/BundleItemExt').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'hallmark/product/PersonalizationArrivalDates').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'hallmark/product/ProductVariantSelectMixin').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/global/SignInModalBtn').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/ajax/LoginFormMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/CrownRewardsModal').then(d => d.default)
    ]).then(deps => {
        const [
            ProductDetailMixin,
            VideoModal,
            DeliveryOptionSelect,
            AddAllToCartMixin,
            ProductAvailabilityMixin,
            AddToCartMixin,
            BundleItemExt,
            PersonalizationArrivalDates,
            ProductVariantSelectMixin,
            SignInModalBtn,
            LoginForm,
            LoginFormMixin,
            CrownRewardsModal
        ] = deps;

        return {
            listId: 'hmk.pdp.widgets',
            widgetsDefinition: () => [
                ['productDetail', ProductDetailMixin, 'productDetail'],
                ['videoModal', VideoModal, 'modal'],
                ['deliveryOptionSelect', DeliveryOptionSelect, 'productOptionSelect'],
                ['productSet', AddAllToCartMixin, 'productSet'],
                ['productDetail', ProductAvailabilityMixin, 'productDetail'],
                ['productDetail', AddToCartMixin, 'productDetail'],
                ['bundleItem', BundleItemExt, 'bundleItem'],
                ['personalizationArrivalDates', PersonalizationArrivalDates],
                ['productVariantSelect', ProductVariantSelectMixin, 'productVariantSelect'],
                ['signInModalBtn', SignInModalBtn],
                ['loginForm', LoginForm, 'ajaxform'],
                ['loginFormMixin', LoginFormMixin, 'loginForm'],
                ['crownRewardsModal', CrownRewardsModal, 'modal']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp') && hasContext('carousel'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.carousel' */ 'hallmark/product/ProductImagesCarouselExt').then(d => d.default)
    ]).then(deps => {
        const [
            ProductImagesCarouselExt
        ] = deps;

        return {
            listId: 'hmk.product.carousel.widgets',
            widgetsDefinition: () => [
                ['productImagesCarousel', ProductImagesCarouselExt, 'productImagesCarousel']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/ajax/LoginFormMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/AjaxFormResponseContainer').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/AjaxFormResponseContainerMigrated').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/ajax/RegistrationForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/ajax/AddressListForm').then(d => d.default),
        import(/* webpackChunkName: 'egiftcertificate.widgets' */ 'widgets/forms/InputNumber').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/checkout/RadioSelector').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/account/MailingPreferences').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/AddressFormExt').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/ajax/EditProfileForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/AccountCrownRewards').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/MissingCrownRewards').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/LinkRewardsForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/CrownRewardsForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/OrderHistoryMgrMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/TrackOrderForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/AddressFormMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/Reminders').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/Offers').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/CrownRewardsHistory').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/CrownRewardsModal').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/HPlusModals').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/LinkAccountsModal').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/ERegistrationForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/HPlusUpdateProfileModal').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/global/SessionExpirationExt').then(d => d.default)
    ]).then(deps => {
        const [
            LoginForm,
            LoginFormMixin,
            AjaxFormResponseContainer,
            AjaxFormResponseContainerMigrated,
            RegistrationForm,
            AddressListForm,
            InputNumber,
            RadioSelector,
            MailingPreferences,
            AddressFormExt,
            EditProfileForm,
            AccountCrownRewards,
            MissingCrownRewards,
            LinkRewardsForm,
            CrownRewardsForm,
            OrderHistoryMgrMixin,
            TrackOrderForm,
            AddressFormMixin,
            Reminders,
            Offers,
            CrownRewardsHistory,
            CrownRewardsModal,
            HPlusModals,
            LinkAccountsModal,
            ERegistrationForm,
            HPlusUpdateProfileModal,
            SessionExpirationExt
        ] = deps;

        return {
            listId: 'hmk.login.account.widgets',
            widgetsDefinition: () => [
                ['loginForm', LoginForm, 'ajaxform'],
                ['loginFormMixin', LoginFormMixin, 'loginForm'],
                ['ajaxFormResponseContainer', AjaxFormResponseContainer, 'modal'],
                ['ajaxFormResponseContainerMigrated', AjaxFormResponseContainerMigrated, 'modal'],
                ['registrationForm', RegistrationForm, 'ajaxform'],
                ['addressListForm', AddressListForm, 'ajaxform'],
                ['inputNumber', InputNumber, 'basicInput'],
                ['radioSelector', RadioSelector, 'inputRadio'],
                ['mailingPreferences', MailingPreferences],
                ['cybersourceAddPaymentMethodForm', AddressFormExt, 'cybersourceAddPaymentMethodForm'],
                ['editProfileForm', EditProfileForm, 'ajaxform'],
                ['accountCrownRewards', AccountCrownRewards],
                ['missingCrownRewards', MissingCrownRewards],
                ['linkRewardsForm', LinkRewardsForm, 'ajaxform'],
                ['crownRewardsForm', CrownRewardsForm, 'ajaxform'],
                ['orderHistoryMgr', OrderHistoryMgrMixin, 'orderHistoryMgr'],
                ['trackorderform', TrackOrderForm, 'ajaxform'],
                ['addressForm', AddressFormMixin, 'addressForm'],
                ['reminders', Reminders],
                ['offers', Offers],
                ['crownRewardsHistory', CrownRewardsHistory],
                ['crownRewardsModal', CrownRewardsModal, 'modal'],
                ['hplusModals', HPlusModals],
                ['linkAccountsModal', LinkAccountsModal, 'modal'],
                ['enterpriseRegistrationForm', ERegistrationForm, 'registrationForm'],
                ['hPlusUpdateProfileModal',HPlusUpdateProfileModal, 'modal'],
                ['sessionExpiration', SessionExpirationExt, 'sessionExpiration']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account-es'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'hallmark/enterprise/ERegistrationTabs').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'hallmark/enterprise/ERegistrationForm').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'hallmark/enterprise/ELoginFormMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'widgets/checkout/PaymentAccordion').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'widgets/checkout/PaymentAccordionItem').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'widgets/checkout/PaymentAccordionItemCREDIT_CARD').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'hallmark/enterprise/EPaymentAccordionItemCC').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'hallmark/enterprise/EPaymentAccordionItemPaypal').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'hallmark/enterprise/EPaymentForm').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'widgets/forms/CouponForm').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'hallmark/forms/CouponFormExt').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'hallmark/enterprise/ECouponFormExt').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'hallmark/forms/InputDate').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/checkout/RadioSelectorMixin').then(d => d.default)
    ]).then(deps => {
        const [
            ERegistrationTabs,
            ERegistrationForm,
            ELoginFormMixin,
            PaymentAccordion,
            PaymentAccordionItem,
            PaymentAccordionItemCREDITCARD,
            EPaymentAccordionItemCC,
            EPaymentAccordionItemPaypal,
            EPaymentForm,
            CouponForm,
            CouponFormExt,
            ECouponFormExt,
            InputDate,
            RadioSelectorMixin
        ] = deps;

        return {
            listId: 'hmk.enterpriseregistration.widgets',
            widgetsDefinition: () => [
                ['enterpriseRegistrationTabs', ERegistrationTabs, 'tabs'],
                ['enterpriseRegistrationForm', ERegistrationForm, 'registrationForm'],
                ['enterpriseLoginFormMixin', ELoginFormMixin, 'loginFormMixin'],
                ['paymentAccordion', PaymentAccordion, 'accordion'],
                ['paymentAccordionItem', PaymentAccordionItem, 'accordionItem'],
                ['paymentAccordionItemCREDIT_CARD', PaymentAccordionItemCREDITCARD, 'paymentAccordionItem'],
                ['enterprisePaymentAccordionItemCC', EPaymentAccordionItemCC, 'paymentAccordionItemCREDIT_CARD'],
                ['enterprisePaymentAccordionItemPaypal', EPaymentAccordionItemPaypal, 'paymentAccordionItem'],
                ['enterprisePaymentForm', EPaymentForm, 'ajaxform'],
                ['couponform', CouponForm, 'ajaxform'],
                ['couponform', CouponFormExt, 'couponform'],
                ['ecouponform', ECouponFormExt, 'couponform'],
                ['inputDate', InputDate, 'inputText'],
                ['radioSelector', RadioSelectorMixin, 'radioSelector']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('subscription'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.subscriptions' */ 'hallmark/forms/ajax/SubscriptionsForm').then(d => d.default)
    ]).then(deps => {
        const [
            SubscriptionsForm
        ] = deps;

        return {
            listId: 'hmk.account.subscriptions',
            widgetsDefinition: () => [
                ['subscriptionsForm', SubscriptionsForm, 'ajaxform']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('cart'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/product/ProductVariantSelect').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/product/ProductOptionSelect').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/product/DeliveryOptionSelect').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/cart/CartMgrExt').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/forms/CouponFormExt').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/global/SignInModalBtn').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/ajax/LoginFormMixin').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/AjaxFormResponseContainer').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/AjaxFormResponseContainerMigrated').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/cart/StorePickupModalInvoker').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/cart/StorePickUpModal').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/cart/StorePickUpShippingForm').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/product/PersonalizationArrivalDates').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/MaskableInput').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/CountryCodeInput').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/CrownRewardsForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/AccountCrownRewards').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/cart/CheckoutButtonsMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/CrownRewardsModal').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'hallmark/global/SessionExpirationExt').then(d => d.default)
    ]).then(deps => {
        const [
            ProductVariantSelect,
            ProductOptionSelect,
            DeliveryOptionSelect,
            CartMgrExt,
            CouponFormExt,
            SignInModalBtn,
            LoginForm,
            LoginFormMixin,
            AjaxFormResponseContainer,
            AjaxFormResponseContainerMigrated,
            StorePickupModalInvoker,
            StorePickUpModal,
            StorePickUpShippingForm,
            PersonalizationArrivalDates,
            MaskableInput,
            CountryCodeInput,
            CrownRewardsForm,
            AccountCrownRewards,
            CheckoutButtonsMixin,
            CrownRewardsModal,
            SessionExpirationExt
        ] = deps;

        return {
            listId: 'hmk.cart.widgets',
            widgetsDefinition: () => [
                ['productVariantSelect', ProductVariantSelect],
                ['personalizationArrivalDates', PersonalizationArrivalDates],
                ['productOptionSelect', ProductOptionSelect, 'productVariantSelect'],
                ['deliveryOptionSelect', DeliveryOptionSelect, 'productOptionSelect'],
                ['cartMgr', CartMgrExt, 'cartMgr'],
                ['couponform', CouponFormExt, 'couponform'],
                ['signInModalBtn', SignInModalBtn],
                ['loginForm', LoginForm, 'ajaxform'],
                ['loginFormMixin', LoginFormMixin, 'loginForm'],
                ['ajaxFormResponseContainer', AjaxFormResponseContainer, 'modal'],
                ['ajaxFormResponseContainerMigrated', AjaxFormResponseContainerMigrated, 'modal'],
                ['storePickupModalInvoker', StorePickupModalInvoker],
                ['storePickUpModal', StorePickUpModal, 'modal'],
                ['storePickUpShippingForm', StorePickUpShippingForm, 'ajaxform'],
                ['inputTel', InputTel, 'inputText'],
                // @ts-ignore TS2339: Property 'sfccData' does not exist on type 'Window & typeof globalThis'.
                ['inputTel', (window.sfccData?.isCountryCodeInputEnabled ? CountryCodeInput : MaskableInput), 'inputTel'],
                ['crownRewardsForm', CrownRewardsForm, 'ajaxform'],
                ['accountCrownRewards', AccountCrownRewards],
                ['checkoutButtons', CheckoutButtonsMixin, 'checkoutButtons'],
                ['crownRewardsModal', CrownRewardsModal, 'modal'],
                ['sessionExpiration', SessionExpirationExt, 'sessionExpiration']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/SmartyAutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/account/AddressListMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'hallmark/forms/InputDate').then(d => d.default),
        import(/* webpackChunkName: 'account.enterprise.widgets' */ 'hallmark/enterprise/EPaymentForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/enterprise/ManageHPlusAccount').then(d => d.default)

    ]).then(deps => {
        const [
            SmartyAutocompleteField,
            AddressListMixin,
            InputDate,
            EPaymentForm,
            ManageHPlusAccount
        ] = deps;

        return {
            listId: 'hmk.account.widgets',
            widgetsDefinition: () => [
                ['smartyAutocompleteField', SmartyAutocompleteField, 'autocompleteField'],
                ['addressList', AddressListMixin, 'addressList'],
                ['inputDate', InputDate, 'inputText'],
                ['enterprisePaymentForm',EPaymentForm,'ajaxform'],
                ['manageHPlusAccount', ManageHPlusAccount]
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'egiftcertificate.widgets' */ 'widgets/forms/InputNumber').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/ajax/RegistrationForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/product/PersonalizationArrivalDates').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/forms/ajax/CheckoutRegistrationForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/checkout/CheckoutMgrExt').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/checkout/CheckoutSummaryMixinExt').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/checkout/ShippingFormExt').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/checkout/BillingFormExt').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/forms/SmartyAutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/forms/ajax/CheckoutLoginFormMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/SummaryStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/CouponForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/forms/CouponFormExt').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/checkout/CustomerStepMixinHmk').then(d => d.default),
		import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/checkout/SummaryStepMixinHmk').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/account/CrownRewardsForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/global/SignInModalBtn').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/forms/ajax/LoginFormMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/checkout/RadioSelectorMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/CrownRewardsModal').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/enterprise/HPlusModals').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'hallmark/global/SessionExpirationExt').then(d => d.default)
    ]).then(deps => {
        const [
            InputNumber,
            RegistrationForm,
            PersonalizationArrivalDates,
            CheckoutRegistrationForm,
            CheckoutMgrExt,
            CheckoutSummaryMixinExt,
            ShippingFormExt,
            BillingFormExt,
            SmartyAutocompleteField,
            CheckoutLoginFormMixin,
            SummaryStepMixin,
            CouponForm,
            CouponFormExt,
            CustomerStepMixinHmk,
			SummaryStepMixinHmk,
            CrownRewardsForm,
            SignInModalBtn,
            LoginForm,
            LoginFormMixin,
            RadioSelectorMixin,
            CrownRewardsModal,
            HPlusModals,
            SessionExpirationExt
        ] = deps;

        return {
            listId: 'hmk.checkout.widgets',
            widgetsDefinition: () => [
                // basicForm / form / ajaxform
                ['inputNumber', InputNumber, 'basicInput'],
                ['registrationForm', RegistrationForm, 'ajaxform'],
                ['personalizationArrivalDates', PersonalizationArrivalDates],
                ['checkoutRegistrationForm', CheckoutRegistrationForm, 'registrationForm'],
                ['checkoutMgr', CheckoutMgrExt, 'checkoutMgr'],
                ['checkoutMgr', CheckoutSummaryMixinExt, 'checkoutMgr'],
                ['checkoutMgr', SummaryStepMixin, 'checkoutMgr'],
                ['shippingForm', ShippingFormExt, 'shippingForm'],
                ['billingForm', BillingFormExt, 'billingForm'],
                ['smartyAutocompleteField', SmartyAutocompleteField, 'autocompleteField'],
                ['checkoutLoginForm', CheckoutLoginFormMixin, 'checkoutLoginForm'],
                ['couponform', CouponForm, 'ajaxform'],
                ['couponform', CouponFormExt, 'couponform'],
                ['checkoutMgr', CustomerStepMixinHmk, 'checkoutMgr'],
				['checkoutMgr', SummaryStepMixinHmk, 'checkoutMgr'],
                ['crownRewardsForm', CrownRewardsForm, 'ajaxform'],
                ['signInModalBtn', SignInModalBtn],
                ['loginForm', LoginForm, 'ajaxform'],
                ['loginFormMixin', LoginFormMixin, 'loginForm'],
                ['radioSelector', RadioSelectorMixin, 'radioSelector'],
                ['crownRewardsModal', CrownRewardsModal, 'modal'],
                ['hplusModals',HPlusModals],
                ['sessionExpiration', SessionExpirationExt, 'sessionExpiration']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('wishlist'),

    load: () => Promise.all([
        import(/* webpackChunkName: 'wishlist.wishlist' */'hallmark/product/QuickBuyMixin').then(d => d.default)
    ]).then(deps => {
        const [
            QuickBuyMixin
        ] = deps;

        return {
            listId: 'hmk.wishlist.wishlist',
            widgetsDefinition: () => [
                ['wishListItem', QuickBuyMixin, 'wishListItem']
            ]
        };
    })
});
