import { TInputHidden } from 'widgets/forms/InputHidden';

/**
 * @description Google Recaptcha Widget Implementation
 * @param InputHidden Base widget for extending
 */
function GoogleRecaptchaClassCreator(InputHidden: TInputHidden) {
    /**
     * @category widgets
     * @subcategory forms
     * @class GoogleRecaptcha
     * @augments TInputHidden
     * @classdesc Google Recaptcha field component with next features:
     * 1. Loads Google Recaptcha script and render recaptcha
     * 2. Executes recaptcha on field validation
     * 3. Handles errors and render error message
     * 4. Handles recaptcha token expiration
     * @property {string} data-widget - Widget name `googleRecaptcha`
     * @property {string} data-site-key - Site key for the Google Recaptcha API
     * @property {string} data-recaptcha-error-message - Error message
     * @property {boolean} data-recaptcha-enabled - Determines if Recaptcha functionality enabled
     * @example <caption>Example of GoogleRecaptcha widget markup</caption>
     * <form
     *     ...
     *     method="POST"
     *     data-widget="ajaxform"
     *     data-event-submit.prevent="handleSubmit"
     * >
     *     ...
     *     <isprint value="${
     *         formElement(pdict.trackOrderForm.googleRecaptcha)
     *         .setData('widget.attributes.data-site-key', googleRecaptchaHelper.getCaptchaKey())
     *         .setData('widget.attributes.data-recaptcha-enabled', googleRecaptchaHelper.isEnabled())
     *         .setData('widget.attributes.data-recaptcha-error-message', Resource.msg('form.recaptcha.error', 'forms', null))
     *         .render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     */
    class GoogleRecaptcha extends InputHidden {
        prefs() {
            return {
                siteKey: '',
                recaptchaErrorMessage: '',
                recaptchaEnabled: false,
                recaptchaAction: '',
                recaptchaMaxRetries: 3,
                recaptchaRetryDelay: 100,
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         */
        init() {
            super.init();
        }

        /**
         * @description Google Captcha success executing handler
         * @param token Google Captcha token
         */
        onRecaptchaSuccess(token: string) {
            this.ref('field').val(token);
        }

        /**
         * @description Google Captcha errors handler
         */
        onRecaptchaError() {
            this.emitWithoutContext('captchainiterror', {
                message: this.prefs().recaptchaErrorMessage
            });
        }

        /**
         * @description Google Captcha expiration handler
         */
        onRecaptchaReset() {
            this.ref('field').val('');
        }

        /**
         * @description Execute Google Recaptcha and returns promise
         * @returns Promise
         */
        async executeRecaptcha(): Promise<string|null> {
            const action = this.prefs().recaptchaAction;
            const siteKey = this.prefs().siteKey;
            const maxRetries = this.prefs().recaptchaMaxRetries || 3;
            const retryDelay = this.prefs().recaptchaRetryDelay || 100;
            const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

            if (!window?.grecaptcha?.enterprise || !action) {
                this.onRecaptchaError();

                return Promise.resolve(null);
            }

            console.group('RECAPTCHA TOKEN');
            let token = await window.grecaptcha.enterprise.execute(siteKey, {action}); // TODO: check if this is async changed to window.enterprise

            for (let attempt = 1; attempt < maxRetries; attempt++) {

                // According to a chat with google, tokens beginning with HF or HL indicate a "BROWSER_ERROR", which can happen when the browser request is blocked (can be simulated by using the network tab in the browser inspector to block the request)
                // Google recommended to retry the .execute() call

                // If it is not a "BROWSER_ERROR", we don't need to retry
                if (!token.startsWith('HF') && !token.startsWith('HL')) {
                    break;
                }

                console.log(`Recaptcha token indicates a BROWSER_ERROR...retrying after a delay`);
                await delay(retryDelay);

                console.log(`Fetching token...retry attempt #${attempt + 1}`);
                token = await window.grecaptcha.enterprise.execute(siteKey, {action});
            }

            if(token.startsWith('HF') || token.startsWith('HL')) {
                console.error('BROWSER_ERROR detected. Using last available token.');
                // throw new Error('BROWSER_ERROR detected');
            }
            console.groupEnd();

            if (typeof token !== 'string') {
                this.onRecaptchaError();
                return null;
            }

            this.onRecaptchaSuccess(token);
            return token;
        }

        /**
         * @description Reloaded method to disable scrolling on validation fail
         */
        setFocus() {
            // Override setFocus logic
        }
    }

    return GoogleRecaptcha;
}

export type TGoogleRecaptcha = ReturnType<typeof GoogleRecaptchaClassCreator>;

export type TGoogleRecaptchaInstance = InstanceType<TGoogleRecaptcha>;

export default GoogleRecaptchaClassCreator;
