import eventBus from 'widgets/toolbox/eventBus';

import { TInputCheckboxInstance } from 'widgets/forms/InputCheckbox';
import { getCookie } from 'widgets/toolbox/cookie';

import {
    getShippingInfo,
    getUrlRefinements,
    getUniqueArray,
    getSearchTotalCount,
    getProductSetSKU,
    getPromoCardApplied,
    getCheckoutFlow,
    updateProductInfoLocation,
    updateSelectedDeliveryOption,
    updateAvailability
} from '../utils/base';

interface IBaseAnalyticsObject {
    productSKU: string;
    productName: string;
}

interface IWriteReviewObject extends IBaseAnalyticsObject {
    action: string;
}

interface ISubmitReviewObject extends IBaseAnalyticsObject {
    reviewValue: string;
    recepient: string;
    countOfReviews: string;
    formStatus: string;
}

interface IInteractionReviewObject extends IBaseAnalyticsObject {
    interaction: string;
}

interface IRefinementObject {
    refinementName?: string;
    refinementValue?: string;
    refinementLocation: string;
    targetUrl?: string;
}

interface IRefinementAnalyticsObject {
    filterType?: string;
    filterValue?: string;
    allFilterType?: string;
    filterLocation?: string;
    allFilterValue?: string;
    allFilterLocation?: string;
}

interface ISearchReviewObject extends IBaseAnalyticsObject {
    productSKU: string;
    productName: string;
    searchReviewTerm: string;
    searchReviewStatus: string;
}

declare global {
    interface Window {
        dataLayer?: Array<any>;
        adobeUserData?: any;
        cachedPageData?: any;
    }

}

// Type for the query parameters
type QueryParams = {
    [key: string]: string | undefined;
    icid?: string;
};

// Interface for the ICID parameter
interface IcidParam {
    icid: string;
}

// Function to get query parameters from a URL
function getQueryParams(url: string): QueryParams {
    const params: QueryParams = {};
    const parser = new URL(url);

    parser.searchParams.forEach((value, key) => {
        params[key] = value;
    });

    return params;
}

/**
 * @description Reports Multi-Message Ribbon active on page when page loads
 */
export function reportMultiMessageRibbonUsage(): void {
    if (document.getElementsByClassName('mmr').length > 0) {
        const mmrElements = document.getElementsByClassName('mmr');
        const mmrArray = Array.from(mmrElements);

        const icidParams: Array<IcidParam> = [];

        mmrArray.forEach(mmrElement => {
            const anchorElements = mmrElement.getElementsByTagName('a');

            Array.from(anchorElements).forEach(anchor => {
                if (anchor.href.includes('icid')) {
                    const params = getQueryParams(anchor.href);

                    if (params.icid) {
                        icidParams.push({ icid: params.icid });
                    }
                }
            });
        });

        window.dataLayer?.push({
            event: 'multiMsgLoad',
            values: icidParams
        });
    }
}


/**
 * @description Init Adobe Events
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function initAdobeEvents(): void {
    /**
     * =======================================================
     *
     * @description Ecommerce DataLayer Adobe Analytics Events
     * =======================================================
     */
    const uniqueRecommenderTypes = {};
    const refinementLocations: Array<string> = [];

    window.dataLayer = window.dataLayer || [];
    reportMultiMessageRibbonUsage();
    /**
     * @description Tracks in dataLayer an information about adding products to cart
     * @event "product.added.to.cart"
     * @listens "product.added.to.cart"
     */
    eventBus.on('product.added.to.cart', (cart, widget, adobeInfo) => {
        let productInfo = adobeInfo
            || (widget && (widget.currentAdobeInfo || widget.data('adobeAnalytics')));
        const storeInfo = window.adobeUserData.storeInfo,
            shippingMethodInfo = getShippingInfo(cart, productInfo),
            freshProductModel = widget && widget.product;

        if (!productInfo) {
            return;
        }

        if (freshProductModel) {
            productInfo = updateSelectedDeliveryOption(productInfo, freshProductModel);
            productInfo = updateAvailability(productInfo, freshProductModel);
        }

        if (!productInfo.quantity) {
            productInfo.quantity = 1;
        }

        productInfo.deviceSource = getCookie('isFromMobileApp') ? 'A' : 'W';

        const productInfoArr = updateProductInfoLocation([productInfo]);

        window.dataLayer?.push({
            event: 'scAdd',
            productSetSKU: productInfo.productSetSKU,
            productInfo: productInfoArr,
            storeInfo: storeInfo,
            shippingMethodInfo: shippingMethodInfo
        });

        // cart initialization
        if (cart.numItems === 1) {
            window.dataLayer?.push({
                event: 'scOpen',
                productSetSKU: productInfo.productSetSKU,
                productInfo: productInfoArr
            });
        }
    });

    /**
     * @description Tracks in dataLayer an information about removing products from cart
     * @event "cart.remove.product"
     * @listens "cart.remove.product"
     */
    eventBus.on('cart.remove.product', (cart, cartMgrWidget, adobeInfo, removeMethod) => {
        if (!adobeInfo && !cartMgrWidget && !cartMgrWidget.removeButton) {
            return;
        }

        const productInfo = adobeInfo || cartMgrWidget.removeButton.data('adobeAnalytics'),
            productremoveMethod = removeMethod || 'Remove from cart',
            storeInfo = window.adobeUserData.storeInfo,
            shippingMethodInfo = getShippingInfo(cart, productInfo);

        if (!productInfo) {
            return;
        }

        if (cartMgrWidget) {
            productInfo.quantity = parseInt(cartMgrWidget.removeButton.data('quantity'), 10) || 1;
        }

        const productInfoArr = updateProductInfoLocation([productInfo]);

        window.dataLayer?.push({
            event: 'scRemove',
            productremoveMethod: productremoveMethod,
            productSetSKU: productInfo.productSetSKU,
            productInfo: productInfoArr,
            storeInfo: storeInfo,
            shippingMethodInfo: shippingMethodInfo
        });
    });

    /**
     * @description Tracks in dataLayer an information about removing products from minicart
     * @event "minicart.remove.product"
     * @listens "minicart.remove.product"
     */
    eventBus.on('minicart.remove.product', (cart, minicartWidget) => {
        if (!minicartWidget.removeProductLink) {
            return;
        }

        const productInfo = minicartWidget.removeProductLink.data('adobeAnalytics'),
            productremoveMethod = 'Remove from cart',
            storeInfo = window.adobeUserData.storeInfo,
            shippingMethodInfo = getShippingInfo(cart, productInfo);

        if (!productInfo) {
            return;
        }

        productInfo.quantity = parseInt(minicartWidget.removeProductLink.data('quantity'), 10) || 1;

        const productInfoArr = updateProductInfoLocation([productInfo]);

        window.dataLayer?.push({
            event: 'scRemove',
            productremoveMethod: productremoveMethod,
            productSetSKU: productInfo.productSetSKU,
            productInfo: productInfoArr,
            storeInfo: storeInfo,
            shippingMethodInfo: shippingMethodInfo
        });
    });

    /**
     * @description Tracks in dataLayer an information about removing products from cart
     * @event "cart.remove.product"
     * @listens "cart.remove.product"
     */
    eventBus.on('cart.open', (adobeCartItems) => {
        if (!adobeCartItems) {
            return;
        }

        adobeCartItems = updateProductInfoLocation(adobeCartItems);

        window.dataLayer?.push({
            event: 'scView',
            user: {
                profile: {
                    deviceSource: getCookie('isFromMobileApp') ? 'A' : 'W'
                }
            },
            productSetSKU: getProductSetSKU(adobeCartItems),
            productInfo: adobeCartItems,
            crownRewardCertAvailable: [],
            storeInfo: window.adobeUserData.storeInfo
        });
    });

    /**
     * @description Tracks in dataLayer an information about customer's filling a shipping step
     * @event "checkout.shipping.step.activated"
     * @listens "checkout.shipping.step.activated"
     */
    eventBus.on('checkout.shipping.step.activated', checkoutMgr => {
        if (checkoutMgr.order && checkoutMgr.order.adobeInfo) {
            const adobeCartItems = updateProductInfoLocation(checkoutMgr.order.adobeInfo.products);
            const totals = checkoutMgr.order.adobeInfo.totals;
            const typeKOC = window.adobeUserData.membershipKOC.type;

            window.dataLayer?.push({
                event: 'scCheckout',
                promoCardCode: totals.coupon,
                KOCmembershipType: typeKOC,
                checkoutstep: 'Shipping Page',
                stepNumber: '2',
                userauthStatus: window.adobeUserData.loginStatus,
                checkoutFlowName: getCheckoutFlow(typeKOC, null, null),
                productSetSKU: getProductSetSKU(adobeCartItems),
                authenticationType: window.adobeUserData.authenticationType,
                user: {
                    profile: {
                        deviceSource: getCookie('isFromMobileApp') ? 'A' : 'W'
                    }
                },
                productInfo: adobeCartItems,
                shippingMethodInfo: getShippingInfo(checkoutMgr.order, null),
                crownRewardCertAvailable: [],
                PromoCardApplied: getPromoCardApplied(totals),
                storeInfo: window.adobeUserData.storeInfo
            });
        }
    });
    /**
     * @description Tracks in dataLayer an information about customer's filling a billing step
     * @event "checkout.billing.step.activated"
     * @listens "checkout.billing.step.activated"
     */
    eventBus.on('checkout.billing.step.activated', checkoutMgr => {
        if (checkoutMgr.order && checkoutMgr.order.adobeInfo) {
            const adobeCartItems = updateProductInfoLocation(checkoutMgr.order.adobeInfo.products);
            const totals = checkoutMgr.order.adobeInfo.totals;
            const typeKOC = window.adobeUserData.membershipKOC.type;

            window.dataLayer?.push({
                event: 'scCheckout',
                promoCardCode: totals.coupon,
                KOCmembershipType: typeKOC,
                checkoutstep: 'Billing Page',
                stepNumber: '3',
                userauthStatus: window.adobeUserData.loginStatus,
                checkoutFlowName: getCheckoutFlow(typeKOC, null, null),
                productSetSKU: getProductSetSKU(adobeCartItems),
                authenticationType: window.adobeUserData.authenticationType,
                user: {
                    profile: {
                        deviceSource: getCookie('isFromMobileApp') ? 'A' : 'W'
                    }
                },
                productInfo: adobeCartItems,
                shippingMethodInfo: getShippingInfo(checkoutMgr.order, null),
                crownRewardCertAvailable: [],
                PromoCardApplied: getPromoCardApplied(totals),
                storeInfo: window.adobeUserData.storeInfo
            });
        }
    });

    /**
     * @description Tracks in dataLayer an information about changed payment method
     * @event "checkout.paymentmethod.changed"
     * @listens "checkout.paymentmethod.changed"
     */
    eventBus.on('checkout.paymentmethod.changed', (paymentMethodName, order) => {
        if (order.adobeInfo) {
            const adobeCartItems = updateProductInfoLocation(order.adobeInfo.products);
            const totals = order.adobeInfo.totals;
            const typeKOC = window.adobeUserData.membershipKOC.type;

            window.dataLayer?.push({
                event: 'scCheckout',
                promoCardCode: totals.coupon,
                KOCmembershipType: typeKOC,
                checkoutstep: 'Payment Page',
                stepNumber: '4',
                userauthStatus: window.adobeUserData.loginStatus,
                checkoutFlowName: getCheckoutFlow(typeKOC, paymentMethodName, null),
                productSetSKU: getProductSetSKU(adobeCartItems),
                authenticationType: window.adobeUserData.authenticationType,
                user: {
                    profile: {
                        deviceSource: getCookie('isFromMobileApp') ? 'A' : 'W'
                    }
                },
                productInfo: adobeCartItems,
                shippingMethodInfo: getShippingInfo(order, null),
                crownRewardCertAvailable: [],
                PromoCardApplied: getPromoCardApplied(totals),
                storeInfo: window.adobeUserData.storeInfo
            });
        }
    });

    eventBus.on('tile.product.link.click', el => {
        // Check if the product is inside a carousel
        const carouselElement = el.get().closest('[data-slot-recommender-name]');
        let productInfo: any = {};

        if (carouselElement) {
            // Product is in the carousel, gather data from the carousel div
            const carouselData = carouselElement.dataset; // Get all data attributes of the carousel
            const recommendationType = carouselData.slotRecommenderName === 'null'
                ? 'Static Product Carousel' : carouselData.slotRecommenderName;

            productInfo.recommendationClick = recommendationType;
        }

        if (el?.data('partnerProduct')) {
            productInfo.partnerProductClick = 'Partner Product';
        }

        localStorage.setItem('DL_productInfo', JSON.stringify(productInfo));
    });

    eventBus.on('einstein.hp.carousel.init', el => {
        const recommendationType = el.dataset.recommender;

        if (recommendationType) {
            window.dataLayer?.push({
                event: 'recommendation',
                recommendationType
            });
        }
    });

    eventBus.on('product.tile.init', refEl => {
        const container = refEl.get().closest('[data-slot-recommender-name]');
        const isMinicart = refEl.get().closest('[data-widget=minicartDialog]');

        if (container) {
            const recommendationType = container.dataset.slotRecommenderName === 'null'
                ? 'Static Product Carousel'
                : container.dataset.slotRecommenderName;

            if (isMinicart) {
                if (container.dataset.analyticUpdated !== 'true' && !uniqueRecommenderTypes[recommendationType]) {
                    container.dataset.analyticUpdated = 'true';

                    window.dataLayer?.push({
                        event: 'recommendation',
                        recommendationType
                    });

                    uniqueRecommenderTypes[recommendationType] = true;
                }
            }

            if (!isMinicart
                && (window.contexts.includes('pdp')
                || window.contexts.includes('cart'))
                && (container.dataset.analyticUpdated !== 'true')
            ) {
                container.dataset.analyticUpdated = 'true';

                window.dataLayer?.push({
                    event: 'recommendation',
                    recommendationType
                });
            }
        }
    });

    /**
     * @description Tracks in dataLayer an information about customer's submitting summary step
     * @event "checkout.summary.step.activated"
     * @listens "checkout.summary.step.activated"
     */
    eventBus.on('checkout.summary.step.activated', checkoutMgr => {
        if (checkoutMgr.order && checkoutMgr.order.adobeInfo) {
            const adobeCartItems = updateProductInfoLocation(checkoutMgr.order.adobeInfo.products);
            const totals = checkoutMgr.order.adobeInfo.totals;
            const typeKOC = window.adobeUserData.membershipKOC.type;
            const selectedPayments = checkoutMgr.order.billing.payment.selectedPaymentInstruments;

            window.dataLayer?.push({
                event: 'scCheckout',
                promoCardCode: totals.coupon,
                KOCmembershipType: typeKOC,
                checkoutstep: 'Review Page',
                stepNumber: '5',
                userauthStatus: window.adobeUserData.loginStatus,
                checkoutFlowName: getCheckoutFlow(typeKOC, null, selectedPayments),
                productSetSKU: getProductSetSKU(adobeCartItems),
                authenticationType: window.adobeUserData.authenticationType,
                user: {
                    profile: {
                        deviceSource: getCookie('isFromMobileApp') ? 'A' : 'W'
                    }
                },
                productInfo: adobeCartItems,
                shippingMethodInfo: getShippingInfo(checkoutMgr.order, null),
                crownRewardCertAvailable: [],
                PromoCardApplied: getPromoCardApplied(totals),
                storeInfo: window.adobeUserData.storeInfo
            });
        }
    });

    /**
     * ===========================================================
     *
     * @description SiteWide DataLayer Adobe Analytics Events
     * ===========================================================
     */

    /**
     * @description Tracks in dataLayer an information about Internal Search Event
     * @event "analytics.search.submit"
     * @listens "analytics.search.submit"
     */
    eventBus.on('analytics.search.submit', (data) => {
        window.dataLayer?.push({
            event: 'internalSearch',
            internalSearch: data
        });
    });

    /**
     * @description Tracks in dataLayer an Buyable Channel Option and Refinements events
     * @event "analytics.filters.selected"
     * @listens "analytics.filters.selected"
     */
    eventBus.on('analytics.filters.selected', (data: IRefinementObject) => {
        // Buyable Channel Option
        if (data.refinementName === 'buyableChannelOption') {
            window.dataLayer?.push({
                event: 'buyableChannelOption',
                buyableChannelOptionType: data.refinementValue
            });

            return;
        }

        // All Refinements Type and Refinements Value
        const refinementinfo: IRefinementAnalyticsObject = {};
        const {
            refinementNames: currentRefinementNames,
            refinementValues: currentRefinementValues
        } = getUrlRefinements(window.location.search);

        refinementLocations.push(data.refinementLocation);

        if (data.refinementName && data.refinementValue) {
            // handle desktop filters selection
            currentRefinementNames.push(data.refinementName);
            currentRefinementValues.push(data.refinementValue);

            refinementinfo.filterType = data.refinementName;
            refinementinfo.filterValue = data.refinementValue;
            refinementinfo.filterLocation = data.refinementLocation;
            refinementinfo.allFilterType = currentRefinementNames.join(' | ');
            refinementinfo.allFilterValue = currentRefinementValues.join(' | ');
            refinementinfo.allFilterLocation = refinementLocations.join(' | ');
        } else if (data.targetUrl) {
            // handle mobile filters selection
            const {
                refinementNames: allRefinementNames,
                refinementValues: allRefinementValues
            } = getUrlRefinements(data.targetUrl);

            const newRefinementNames = getUniqueArray(currentRefinementNames, allRefinementNames);
            const newRefinementValues = getUniqueArray(currentRefinementValues, allRefinementValues);

            if (newRefinementNames.includes('buyableChannel')) {
                const optionValue = newRefinementValues[newRefinementNames.indexOf('buyableChannel')];

                window.dataLayer?.push({
                    event: 'buyableChannelOption',
                    buyableChannelOptionType: optionValue.replace(/-/g, ' ')
                });

                return;
            }

            refinementinfo.filterLocation = data.refinementLocation;
            refinementinfo.filterType = newRefinementNames.join(' | ');
            refinementinfo.filterValue = newRefinementValues.join(' | ');
            refinementinfo.allFilterType = allRefinementNames.join(' | ');
            refinementinfo.allFilterValue = allRefinementValues.join(' | ').replace(/-/g, ' ');
            refinementinfo.allFilterLocation = refinementLocations.join(' | ');
        }

        if (Object.keys(refinementinfo).length > 0) {
            window.dataLayer?.push({
                event: 'refinement',
                refinementinfo: refinementinfo
            });
        }
    });

    /**
     * @description Tracks in dataLayer a send Sort Event
     * @event "analytics.sort.selected"
     * @listens "analytics.sort.selected"
     */
    eventBus.on('analytics.sort.selected', (sortType: string) => {
        sortType = sortType.trim();

        if (sortType) {
            window.dataLayer?.push({
                event: 'sort',
                sortType
            });
        }
    });

    /**
     * @description Tracks in dataLayer a write review event
     * @event "analytics.write.review"
     * @listens "analytics.write.review"
     */
    eventBus.on('analytics.write.review', ({ action, productSKU, productName }: IWriteReviewObject) => {
        window.dataLayer?.push({
            event: 'ratingreview',
            eventAction: action,
            productDetail: { productSKU, productName }
        });
    });

    /**
     * @description Tracks in dataLayer a review form submission event
     * @event "analytics.review.form.submission"
     * @listens "analytics.review.form.submission"
     */
    eventBus.on(
        'analytics.review.form.submission',
        ({
            reviewValue,
            recepient,
            productName,
            productSKU,
            countOfReviews,
            formStatus
        }: ISubmitReviewObject) => {
            window.dataLayer?.push({
                event: 'reviewFormSubmission',
                eventAction: 'Rating Review',
                reviewFormSubmission: { reviewValue, recepient },
                productDetail: { productName, productSKU, countOfReviews },
                formInfo: {
                    formName: 'Submit my review.',
                    formStatus
                }
            });
        }
    );

    /**
     * @description Tracks in dataLayer a Rating & Review Interaction event
     * @event "analytics.review.interaction"
     * @listens "analytics.review.interaction"
     */
    eventBus.on(
        'analytics.review.interaction',
        ({
            productSKU,
            productName,
            interaction
        }: IInteractionReviewObject) => {
            window.dataLayer?.push({
                event: 'ratingReviewInteraction',
                eventAction: interaction,
                productDetail: { productSKU, productName }
            });
        }
    );

    /**
     * @description Tracks in dataLayer a Search Review event
     * @event "analytics.search.review"
     * @listens "analytics.search.review"
     */
    eventBus.on(
        'analytics.search.review',
        ({
            productSKU,
            productName,
            searchReviewTerm,
            searchReviewStatus
        }: ISearchReviewObject) => {
            window.dataLayer?.push({
                event: 'searchReview',
                searchReviewTerm,
                searchReviewStatus,
                productDetail: { productSKU, productName }
            });
        }
    );

    /**
     * @description Tracks in dataLayer a Sign up for emails, offers and updates event
     * @event "analytics.news.signup"
     * @listens "analytics.news.signup"
     */
    eventBus.on(
        'analytics.news.signup',
        ({
            status
        }: { status: string }) => {
            window.dataLayer?.push({
                event: 'newsSignUp',
                status
            });
        }
    );

    /**
     * @description Tracks in dataLayer a Promo Code or Crown Reward Code Click event
     * @event "promo.code.click"
     * @listens "promo.code.click"
     */
    eventBus.on(
        'promo.code.click',
        () => {
            window.dataLayer?.push({
                event: 'promoCrownCardCodeClick'
            });
        }
    );

    /**
     * @description Tracks in dataLayer when user chose between the shipment methods on checkout event
     * @event "checkout.update.shipping.method"
     * @listens "checkout.update.shipping.method"
     */
    eventBus.on(
        'checkout.update.shipping.method',
        ({ shipping }) => {
            const shippingOptionTypes = {
                S: 'Standard',
                E: 'Expediate',
                R: 'Rush'
            };
            const shippingOptionCode = shipping[0].selectedShippingMethod.shippingOptionCode;
            const shipmentType = shippingOptionTypes[shippingOptionCode];
            const shipmentPrice = shipping[0].selectedShippingMethod.shippingCost.priceFinalValue;
            const shipmentLocation = 'One Location';

            window.dataLayer?.push({
                event: 'shippingMethod',
                shippingMethodInfo: {
                    shipmentType,
                    shipmentPrice,
                    shipmentLocation
                }
            });
        }
    );

    /**
     * @description Tracks in dataLayer an Add to Favorites event
     * @event "add.to.favorites"
     * @listens "add.to.favorites"
     */
    // TODO: calc listType for Keepsake Wish List
    eventBus.on(
        'add.to.favorites',
        (data) => {
            window.dataLayer?.push({
                event: 'AddToFav',
                ...data
            });
        }
    );

    /**
     * @description Tracks in dataLayer send submit payment checkout step event
     * @event "pay.method.selected"
     * @listens "pay.method.selected"
     */
    // TODO: calc paymentType If multiple selection are there pass the value by '|' separated.
    eventBus.on(
        'pay.method.selected',
        (data) => {
            window.dataLayer?.push({
                event: 'payMethod',
                ...data
            });
        }
    );

    /**
     * @description Tracks in dataLayer a video interaction event
     * @event "video.interaction"
     * @listens "video.interaction"
     */
    eventBus.on(
        'video.interaction',
        (data) => {
            window.dataLayer?.push({
                event: 'hallmarkVideo',
                ...data
            });
        }
    );

    /**
     * @description Tracks in dataLayer a Product Thumbnail View event
     * @event "product.thumbnail.view"
     * @listens "product.thumbnail.view"
     */
    eventBus.on(
        'product.thumbnail.view',
        (data) => {
            window.dataLayer?.push({
                event: 'productThumbnail',
                ...data
            });
        }
    );

    /**
     * @description Tracks in dataLayer an In-Store Search Result event
     * @event "store.search.result"
     * @listens "store.search.result"
     */
    eventBus.on(
        'store.search.result',
        (data) => {
            window.dataLayer?.push({
                event: 'inStoreSearch',
                ...data
            });
        }
    );

    /**
     * @description Tracks in dataLayer form submit event
     * @event "analytics.form.submited"
     * @listens "analytics.form.submited"
     */
    eventBus.on(
        'analytics.form.submited',
        (response, formWidget) => {
            const formName = formWidget.prefs().analyticsFormName;

            if (!formName) {
                return;
            }

            let formStatus = response.success ? 'Success' : 'Failed';

            // calc First Interaction formStatus
            let interactedForms: string | Array<string> | null = window.sessionStorage.getItem('interactedForms');
            let isfirstInteraction = false;

            if (!interactedForms) {
                interactedForms = [];
                interactedForms.push(formName);
                window.sessionStorage.setItem('interactedForms', JSON.stringify(interactedForms));

                isfirstInteraction = true;
            } else {
                const parsedInteractedForms = JSON.parse(interactedForms);

                if (!parsedInteractedForms.includes(formName)) {
                    parsedInteractedForms.push(formName);
                    window.sessionStorage.setItem('interactedForms', JSON.stringify(parsedInteractedForms));

                    isfirstInteraction = true;
                }
            }

            if (isfirstInteraction) {
                formStatus = 'First Interaction | ' + formStatus;
            }

            // calc formOptions
            let formOption: Array<string> = [];

            if (formWidget.prefs().analyticsSubscriptionForm) {
                const fields = formWidget.getFormFields();

                formOption = Object.keys(fields).reduce((accumulator: Array<string>, key: string) => {
                    if (key.toLowerCase().includes('email') && fields[key]) {
                        accumulator.push('Email');
                    } else if (key.toLowerCase().includes('mail') && fields[key]) {
                        accumulator.push('Post');
                    } else if (key.toLowerCase().includes('text') && fields[key]) {
                        accumulator.push('SMS');
                    }

                    return accumulator;
                }, []);
            }

            if (formWidget.prefs().analyticsEditProfileForm) {
                const fields = formWidget.getFormFields();

                formOption = Object.keys(fields).reduce((accumulator: Array<string>, key: string) => {
                    if (key.toLowerCase().includes('birthday') && fields[key]) {
                        accumulator.push('Birth Day');
                    } else if (key.toLowerCase().includes('birthmonth') && fields[key]) {
                        accumulator.push('Birth Month');
                    } else if (key.toLowerCase().includes('phone') && fields[key]) {
                        accumulator.push('Mobile');
                    } else if (key.toLowerCase().includes('signup') && fields[key]) {
                        accumulator.push('Marketing');
                    }

                    return accumulator;
                }, []);
            }

            if (formWidget.prefs().analyticsRegistrationForm) {
                formWidget.getById('isCRMember', (inputCheckbox: TInputCheckboxInstance) => {
                    const checked = Boolean(inputCheckbox.getValue());

                    formOption.push(checked ? 'Existing Crown Reward Member' : 'Standard Member');
                });
            }

            if ('isCRCoupon' in response) {
                formOption.push(response.isCRCoupon ? 'crownCode' : 'promoCode');
            }

            const analyticsFormOptions = formWidget.prefs().analyticsFormOptions;

            if (analyticsFormOptions) {
                formOption.push(analyticsFormOptions);
            }

            const form: {
                formName: string;
                formStatus: string;
                formOption: string | undefined;
                appliedAmount?: string;
            } = {
                formName,
                formStatus,
                formOption: formOption.length ? formOption.join(' | ') : undefined
            };

            if ('couponAppliedAmount' in response) {
                form.appliedAmount = response.couponAppliedAmount;
            }

            window.dataLayer?.push({
                event: 'form',
                form
            });
        }
    );

    /**
     * @description Tracks in dataLayer CR Linking with .com event
     * @event "analytics.link.membership"
     * @listens "analytics.link.membership"
     */
    eventBus.on('analytics.link.membership', () => {
        window.dataLayer?.push({
            event: 'linkMembership'
        });
    });

    /**
     * @description Tracks in dataLayer Personalization Flow event
     * @event "analytics.link.membership"
     * @listens "analytics.link.membership"
     */
    eventBus.on('analytics.personalization', (stepName, btnWidget) => {
        const productName = btnWidget.prefs().productName;
        // TODO: calc productType dynamically
        const productType = 'Photo card';

        window.dataLayer?.push({
            event: 'personalization',
            productType,
            productName,
            stepName
        });
    });

    /**
     * @description Tracks in dataLayer Error Message event
     * @event "analytics.error.message"
     * @listens "analytics.error.message"
     */
    eventBus.on('analytics.error.message', (messageType) => {
        window.dataLayer?.push({
            event: 'errorMessage',
            messageType
        });
    });

    /**
     * @description Tracks in dataLayer an information about adding KOC product to cart
     * @event "product.added.to.cart"
     * @listens "product.added.to.cart"
     */
    eventBus.on('product.added.to.cart.koc', (kocEvent) => {
        window.dataLayer?.push({
            event: 'kocClub',
            eventAction: kocEvent
        });
    });

    /**
     * @description Tracks in dataLayer Error Message event
     * @event "analytics.error.message"
     * @listens "analytics.error.message"
     */
    eventBus.on('koc.wishlist.sent', (wishlistModel) => {
        let quantity = 0;
        const itemsList: Array<object> = [];

        wishlistModel?.items?.forEach(item => {
            const itemInfo = {
                productSKU: item.pid,
                quantity: item.qty
            };

            itemsList.push(itemInfo);
            quantity += item.qty;
        });
        window.dataLayer?.push({
            event: 'sendListToStore',
            listInfo: {
                listType: 'Keepsake Wish List',
                quantity: quantity,
                numberOfLinesItemSent: wishlistModel?.items.lenth,
                productInfo: itemsList,
                totalQuantity: quantity
            }
        });
    });

    /**
     * @description Tracks in dataLayer event when user is displayed modal to create Crown Rewards Number
     * @event "analytics.hplus.createcr.modal"
     * @listens "analytics.hplus.createcr.modal"
     */
    eventBus.on('analytics.hplus.createcr.modal', (data) => {
        window.dataLayer?.push({
            event: 'widget',
            clickedElement: 'Automatic',
            widgetName: 'Crown Rewards Consent'
        });
    });

    /**
     * @description Tracks in dataLayer event when user agrees to create CR Number
     * @event "analytics.createcr.modal.save"
     * @listens "analytics.createcr.modal.save"
     */
    eventBus.on('analytics.createcr.modal.save', (data) => {
        window.dataLayer?.push({
            event: 'form',
            clickedElement: 'Crown Rewards Consent',
            formStatus: data.success ? 'Success' : 'Failed'
        });
    });

    /**
     * @description Tracks in dataLayer event when user is displayed modal to update missing profile info
     * @event "analytics.hplus.updateprofile.modal"
     * @listens "analytics.hplus.updateprofile.modal"
     */
    eventBus.on('analytics.hplus.updateprofile.modal', (data) => {
        window.dataLayer?.push({
            event: 'widget',
            clickedElement: 'Automatic',
            widgetName: 'Automatic Update Profile'
        });
    });

    /**
     * @description Tracks in dataLayer event when user save payment success
     * @event "analytics.hplus.save.payment.success"
     * @listens "analytics.hplus.save.payment.success"
     */
    eventBus.on('analytics.hplus.save.payment.success', () => {
        window.dataLayer?.push({
            event: 'form',
            form: {
                formName: 'H+ Membership Save Payment',
                formStatus: 'Success'
            }
        });
    });
    /**
     * @description Tracks in dataLayer event when user save payment failure
     * @event "analytics.hplus.save.payment.failed"
     * @listens "analytics.hplus.save.payment.failed"
     */
    eventBus.on('analytics.hplus.save.payment.failed', () => {
        window.dataLayer?.push({
            event: 'form',
            form: {
                formName: 'H+ Membership Save Payment',
                formStatus: 'Failed'
            }
        });
    });

    /**
     * @description Tracks in dataLayer event when user change plan successful
     * @event "analytics.hplus.change.plan"
     * @listens "analytics.hplus.change.plan"
     */
    eventBus.on('analytics.hplus.change.plan', (data) => {
        window.dataLayer?.push({
            event: 'form',
            form: {
                formName: 'H+ Membership Save Plan',
                formStatus: data.type,
                formOption: data.selectedPlan
            }
        });
    });

    /**
     * @description Tracks in dataLayer event when hplus subcription cancel modal
     * @event "analytics.hplus.cancel.modal"
     * @listens "analytics.hplus.cancel.modal"
     */
    eventBus.on('analytics.hplus.cancel.modal', () => {
        window.dataLayer?.push({
            event: 'widget',
            clickedElement: 'Cancel membership',
            widgetName: 'H+ Membership Cancel'
        });
    });

    /**
     * @description Tracks in dataLayer event when hplus subcription cancel confirmation
     * @event "analytics.hplus.cancel.confirm"
     * @listens "analytics.hplus.cancel.confirm"
     */
    eventBus.on('analytics.hplus.cancel.confirm', (data) => {
        window.dataLayer?.push({
            event: 'form',
            form: {
                formName: 'H+ Membership Cancel',
                formStatus: data.type,
                formOption: data.selectedPlan
            }
        });
    });

    /**
     * @description Tracks in dataLayer event when hplus subcription keep membership
     * @event "analytics.hplus.keep.membership"
     * @listens "analytics.hplus.keep.membership"
     */
    eventBus.on('analytics.hplus.keep.membership', (data) => {
        window.dataLayer?.push({
            event: 'form',
            form: {
                formName: 'H+ Membership Cancel',
                formStatus: data.type,
                formOption: data.selectedPlan
            }
        });
    });

    /**
     * @description Tracks in dataLayer event when user remove coupon
     * @event "analytics.coupon.remove"
     * @listens "analytics.coupon.remove"
     */
    eventBus.on('analytics.coupon.remove', () => {
        window.dataLayer?.push({
            clickedElement: 'Unselect Coupon',
            event: 'widget'
        });
    });

    /**
     * ==========================================================================
     *
     * @description Sent Widgets Site Wide Data Layer Event
     * (when any link or radio button clicked that doesn't redirect to any page)
     * ========================================================================
     */

    const trackClickedElementsText = (el) => {
        el.addEventListener('click', (event: Event) => {
            const target = event.target as HTMLElement;

            if (target.textContent) {
                const clickedElement = target.textContent.trim();

                window.dataLayer?.push({
                    event: 'widget',
                    widgetName: el.dataset.widgetName,
                    clickedElement
                });
            }
        });
    };

    document.querySelectorAll('a').forEach((el) => trackClickedElementsText(el));
    document.querySelectorAll('button').forEach((el) => trackClickedElementsText(el));
    document.querySelectorAll('input[type="radio"], [role="radio"]').forEach((el) => trackClickedElementsText(el));

    /**
     * ==========================================================================
     *
     * @description Sent Internal Search Result Site Wide Data Layer Event
     * (when search param exists in url)
     * ========================================================================
     */
    const urlParams = new URLSearchParams(window.location.search);
    const categoryBloomreachRedirect = document.querySelector<HTMLElement>('[data-bloomreach-search]');
    const searchTerm = urlParams.get('q') || categoryBloomreachRedirect?.dataset.bloomreachSearch;

    if (searchTerm) {
        const searchURL = window.location.href;
        const searchTotalCount = getSearchTotalCount();
        const searchResult = window.contexts.includes('pdp')
            ? 1
            : parseInt(searchTotalCount, 10);

        window.dataLayer?.push({
            event: 'internalSearchResult',
            internalSearchResult: {
                searchTerm,
                searchResult,
                searchURL,
                searchStatus: searchResult ? 'Success' : 'Failed',
                searchType: searchResult ? 'Results' : 'No Results'
            }
        });
    }
}
